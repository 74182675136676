.task-card {
    width: 275px;
    padding: 16px 12px;
    cursor: grab;
    opacity: 0.99;
    border-radius: 10px;
    background: #1F2124;
}

.task-card:active {
    cursor: grabbing;
}

.task-card.dragging {
    background: #303660 !important;
    cursor: grabbing;
}

.task-card .header {
    display: flex;
    margin: 0 0 15px 0;
    align-items: center;
    justify-content: space-between;
}

.task-card .title {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
}

.task-card .subtitle {
    font-size: 14px;
    color: #929292;
    margin: 8px 0 0 0;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.5px;
}

.task-card .header .icon {
    margin: 0 7px 0 0;
}

.task-card .tags {
    gap: 7px;
    display: flex;
    flex-direction: row;
}

.task-card .date-button {
    display: flex;
    gap: 7px;
    margin: 20px 0;
    font-size: 14px;
    padding: 10px 12px;
    width: max-content;
    border-radius: 10px;
    border: 1px solid #424346;
}


.task-card .date-button .date {
    font-weight: 400;
}

.task-card .footer {
    display: flex;
    justify-content: space-between;
    color: #A5A5A5;
    padding: 15px 0 10px 0;
    border-top: 1px solid #4f5155;
}

.task-card .footer .stats {
    display: flex;
    gap: 15px;
}

.task-card .footer .stats .comments {
    display: flex;
    gap: 5px;
}

.task-card .footer .stats .attachments {
    display: flex;
    gap: 5px;
}