.project-section {
    width: 300px;
    padding: 20px 0;
}

.project-section .heading {
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;
    justify-content: space-between;
}

.project-section .heading .title {
    font-size: 35px;
    font-weight: 500;
    color: #2E3034;
}

.project-section .heading .indicator {
    width: 10px;
    height: 10px;
    border-radius: 20px;
}