.tag {
    display: flex;
    padding: 5px 10px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
}

.tag .title {
    font-size: 12px !important;
    font-weight: 500 !important;
    letter-spacing: 0px !important;
}