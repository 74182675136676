.search-bar {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #232427;
}

.search-bar .search-input {
    gap: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.search-bar .search-input input {
    height: 50px;
    font-size: 16px;
    width: 300px;
    border: none;
    color: white;
    background: transparent;

    ::placeholder {
        color: white
    }

    &:focus {
        outline: none;
    }

    &:focus-visible {
        outline: none;
    }
}

.search-bar .search-actions {
    display: flex;
    flex-direction: row;
}

.search-bar .search-actions .action-button {
    padding: 10px 20px;
    border-radius: 0px;
    border-right: 1px solid #2C2D2E;
}

.search-bar .search-actions .action-button:hover {
    border-top: unset;
    border-bottom: unset;
    border-left: 1px solid transparent;
    border-right: 1px solid #2C2D2E;
    background-color: transparent !important;
}

.search-bar .search-actions .action-button:hover span {
    color: #5E69DA
}

.search-bar .search-actions .action-button:hover svg {
    color: #5E69DA
}