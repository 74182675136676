.app-bar {
    display: flex;
    width: 100%;
    height: 175px;
    align-items: center;
    background: #1F2124;
}

.app-bar .action-buttons {
    gap: 10px;
    display: flex;
    flex-direction: row;
    margin: 0 0 0 30px;
    align-items: center;
}

.app-bar .action-buttons .action-button {
    height: 20px;
    padding: 10px 15px;
}

.app-bar .action-buttons .separator {
    width: 2px;
    height: 30px;
    background-color: #393B3C;
}

.app-bar .title {
    font-size: 22px;
    font-weight: 500;
    padding: 0 20px;
}