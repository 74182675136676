.member-card {
    height: 40px;
    display: flex;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 12px;
    align-items: center;
    border: .5px solid #929292;
}

.member-card:hover {
    border-color: white;
}

.trailing {
    gap: 3.5px;
    display: flex;
    margin-left: 10px;
    flex-direction: column;
}

.title {
    font-weight: 600;
}

.subtitle {
    font-size: 14px;
    color: #929292;
}

img {
    width: 30px;
    height: 30px;
    border-radius: 35px;
}