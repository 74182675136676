.sidebar {
    display: flex;
    width: 350px;
    background-color: #1F2124;
}

.sidebar h3 {
    font-size: 22px;
    margin: 20px 0;
}

.action-buttons {
    margin: 0;
    flex-direction: column;
}

.right-section .action-buttons {
    gap: 7px;
    display: flex;
    margin: 0 0 30px 0
}

.left-section .action-buttons {
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.left-section .action-buttons .top {
    gap: 7px;
    display: flex;
    flex-direction: column;
}

.left-section .action-buttons .bottom {
    gap: 7px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.left-section .action-buttons .action-button {
    display: block;
}

.left-section .action-buttons .action-button:hover {
    border-color: white;
}

.left-section .action-buttons .bottom .profile-icon {
    cursor: pointer;
    border-radius: 15px;
    border: 1px solid transparent
}

.left-section .action-buttons .bottom .profile-icon:hover {
    border: 1px solid #5E69DA;
}

.left-section .action-buttons .bottom .profile-icon:hover img {
    opacity: 0.35s;
}

.left-section .action-buttons .bottom .profile-icon img {
    width: 48px;
    height: 48px;
    border-radius: 15px;
}

.members-heading {
    display: flex;
    margin: 0 0 10px 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.add-icon {
    cursor: pointer;
    padding: 12px 13px;
    border-radius: 50px;
    border: 1.5px solid #393B3D;
}

.add-icon:hover {
    border: 1.5px solid #5b69e1;
    background: rgba(0, 0, 0, 0.1);
}

.member-cards {
    gap: 7px;
    display: flex;
    flex-direction: column;
}

.left-section {
    display: flex;
    width: 50px;
    padding: 20px 15px;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid #242528;
}

.right-section {
    width: 250px;
    padding: 0 20px;
    border-right: 1px solid #242528;
}

.logo {
    margin-bottom: 50px;
}


.right-section .action-buttons {
    gap: 10px
}