@import url('../styles/reset.css');
@import url('../styles/font.css');

.App {
  text-align: center;
  background-color: #1F2124;
}

#root {
  height: 100%;
  color: white;
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -1px;
}

body {
  background-color: #1F2124;
}

.app-wrapper {
  height: 100%;
  display: flex;
  overflow: hidden;
}