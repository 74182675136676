.publications {
    display: flex;
    overflow: hidden;
    width: calc(100% - 350px);
    flex-direction: column;
    background: #131415;
}

.publications .project-board {
    display: flex;
    gap: 20px;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 0 20px;
}

.publications .project-board .task-cards {
    gap: 15px;
    display: flex;
    flex-direction: column;
}