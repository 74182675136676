.action-button {
    display: flex;
    padding: 16px;
    border-radius: 16px;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    border: 1px solid transparent;
}


.action-button:hover {
    border: 1px solid white;
    background: rgba(50, 50, 50, 0.1) !important;
}

.leading {
    gap: 10px;
    display: flex;
}